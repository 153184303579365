const initialState = {
  selectedFactory: 'all',
  lanes: [],
  allLanes: [],
  details: {
    box: null,
    action: '',
    lane: '',
    scannedPubkeys: [],
    variantsScannedPubkey: [],
    scannedShippingLabel: false,
    labelsReprint: [],
  },
  fix: null,
  nextPreview: null,
  nextBoxInlane: null,
  dispatch: {
    selectedLanes: [],
  },
  printSuccess: false,
  error: null,
};
export default initialState;
