import * as Sentry from '@sentry/react';
import { history } from 'store';

import stringToBoolOrValue from './envParsing';
import getEnv from './getEnv';

const initSentry = () => {
  Sentry.init({
    dsn: getEnv('REACT_APP_SENTRY'),
    environment: getEnv('REACT_APP_ENV') ?? getEnv('MODE'),
    release: `prose-backoffice@${getEnv('REACT_APP_VERSION_BACKOFFICE')}`,
    normalizeDepth: 8,
    integrations: [
      new Sentry.Replay({
        maskAllText: true,
        maskAllInputs: true,
        blockAllMedia: false,
      }),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history as any),
        tracePropagationTargets: [getEnv('REACT_APP_ROOT_URL')],
      }),
    ],
    ignoreErrors: [
      'ChunkLoadError',
      'TypeError: Failed to fetch',
      'Unauthenticated',
      'Cannot refresh token, must signin',
      /Failed to fetch dynamically imported module:/,
    ],
    ...(getEnv('REACT_APP_ENV') !== 'development'
      ? {
          tracesSampleRate: Number(getEnv('REACT_APP_SENTRY_RATE')),
          // The sample rate for replays that are recorded for user journeys when no error happens
          replaysSessionSampleRate: Number(getEnv('REACT_APP_SENTRY_SESSION_REPLAY_RATE')),
          // The sample rate for replays that are recorded when an error happens
          replaysOnErrorSampleRate: Number(getEnv('REACT_APP_SENTRY_ERROR_REPLAY_RATE')),
        }
      : {}),
    ...(getEnv('REACT_APP_ENV') === 'development' &&
    stringToBoolOrValue(getEnv('REACT_APP_SENTRY_SPOTLIGHT'))
      ? {
          spotlight: true,
        }
      : {}),
  });
};

export default initSentry;
