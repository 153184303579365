import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import _ from 'lodash/fp/placeholder';

import * as Sentry from '@sentry/browser';

import logSentryError from 'utils/sentry';
import getAbsoluteURL from '../utils/getAbsoluteURL';

import { signout } from '../dux/auth/slice';
import HTTPError from '../Errors/HTTPError';
import type {
  AuthentifiedRequestFunc,
  GetFetchParamsWithAuth,
  InitApiClient,
  IsAuthError,
} from './ApiClient.types';
import APIResponse from './ApiResponse';
import { getAuth, refresh } from './Auth';
import defaultHeaders from './DefaultHeaders';

const isAuthErrorMessage: IsAuthError = includes(_, [
  'Cannot refresh token, must signin',
  'Unauthenticated',
]);

const getFetchParamsWithAuth: GetFetchParamsWithAuth = (
  method,
  params,
  headerOverrides = {},
  signal = undefined
) => {
  let authHeaders = {};

  try {
    const auth = getAuth();
    authHeaders = {
      ...authHeaders,
      ...(auth?.accessToken ? { Authorization: `Bearer ${auth.accessToken}` } : {}),
    };
  } catch (e) {
    logSentryError('[ApiClient getFetchParamsWithAuth] error', e);
  }

  return {
    method,
    headers: { ...defaultHeaders, ...authHeaders, ...headerOverrides }, // spread by order of priority
    referrerPolicy: 'unsafe-url',
    ...(signal ? { signal } : {}), // add abort controller signal if necessary
    ...(params ? { body: JSON.stringify(params) } : {}), // body is set only if it has params
  };
};

const authentifiedRequest: AuthentifiedRequestFunc =
  (method, dispatch) => async (route, params, headers, signal) => {
    // Naively try to fetch the resource
    let response = await fetch(
      getAbsoluteURL(route),
      getFetchParamsWithAuth(method, params, headers, signal)
    );
    // If it succeeded directly return the response
    if (response.ok) {
      return new APIResponse(response);
    }
    // If it failed with a 401 try naively to refresh the token without extra checks
    if (response.status === 401) {
      try {
        await refresh();
      } catch (error) {
        const message: string = get('message', error);
        if (isAuthErrorMessage(message)) {
          // signout the user since we failed to refresh the token
          dispatch(signout()); // Use the redux action signout so it both deletes the local storage and redirects to login
        }
        throw error;
      }
      // After refresh try to fetch again (notice that we build params again since auth is refreshed)
      response = await fetch(
        getAbsoluteURL(route),
        getFetchParamsWithAuth(method, params, headers, signal)
      );
      // If it succeded return the response
      if (response.ok) {
        return new APIResponse(response);
      }
    }
    // In any other non-successful scenario, log an error with the response.status
    Sentry.configureScope(scope => {
      scope.setExtra('api:fetchError', response.status);
    });
    // Throw an error with the status and the statusText
    throw new HTTPError(response.status, await response.json());
  };

const initApiClient: InitApiClient = dispatch => ({
  // GET requests needs to put params in the URL
  get: (route, params, headers = undefined, signal = undefined) =>
    authentifiedRequest('GET', dispatch)(
      params ? `${route}?${new URLSearchParams(params)}` : route,
      undefined,
      headers,
      signal
    ),
  patch: authentifiedRequest('PATCH', dispatch),
  put: authentifiedRequest('PUT', dispatch),
  post: authentifiedRequest('POST', dispatch),
  del: authentifiedRequest('DELETE', dispatch),
});

export default initApiClient;
